














import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppInput extends Vue {
  @Prop({ type: String, required: false })
  readonly id!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false })
  readonly value!: string | number;

  @Prop({ type: String, required: false })
  readonly autocomplete!: string;

  @Prop({ type: String, required: false })
  readonly helperId!: string;

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean;

  @Prop({ type: String, default: "text" })
  readonly type!: "text" | "hidden" | "password" | "email";
}
