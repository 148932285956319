












import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class AppInputGroup extends Vue {
  hasPrepend(): boolean {
    return !!this.$slots.prepend;
  }

  hasAppend(): boolean {
    return !!this.$slots.append;
  }
}
